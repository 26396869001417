import React from "react";
import PropTypes from "prop-types";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import moment from "moment";
import axios from "axios";
import delay from "lodash/delay";
import debounce from "lodash/debounce";



const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
function bytesToSize(bytes) {
  if (bytes === 0) {
    return "0 Bytes";
  }
  let ii = Math.floor(Math.log(bytes) / Math.log(1024));
  return Math.round(bytes / Math.pow(1024, ii)) + " " + sizes[ii];
}

function toRelativeTime(timestamp) {
  return moment(timestamp).fromNow();
}

class DatasetTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      files: [],
      page: 0,
      fileDownloadDisabled: false,
    };
    if (!props.datasetName || !props.datasetVersion) {
      this.state.isLoaded = true;
      this.state.error = new Error("Cannot load unknown dataset");
    }
  }

  _loadFiles(datasetName, datasetVersion) {
    if (!datasetName || !datasetVersion) {
      console.debug(datasetName, datasetVersion);
      return Promise.resolve({
        error: new Error("Cannot load unknown dataset"),
      });
    }
    return axios
      .get(`/datasets/${datasetName}/versions/${datasetVersion}/files`, {
        params: {
          maxKeys: 10,
          sorting: "desc",
          orderBy: "created"
        },
      })
      .then((response) => {
        if (response.data === undefined || response.data.files === undefined) {
          throw new Error("Could not load files");
        }
        return {
          isLoaded: true,
          files: response.data.files,
        };
      })
      .catch(
        // Don't swallow the error
        (error) => {
          return {
            isLoaded: true,
            error,
          };
        }
      );
  }

  componentDidMount() {
    const { datasetName, datasetVersion } = this.props;
    if (datasetName && datasetVersion) {
      this._loadFiles(datasetName, datasetVersion).then(
        (stateChange) => {
          this.setState((state, props) => {
            if (
              props.datasetName === datasetName &&
              props.datasetVersion === datasetVersion
            ) {
              return stateChange;
            } else {
              return {};
            }
          });
        }
      );
    }
  }

  componentWillUnmount() {
    this.setState({
      isLoaded: false,
      files: [],
    });
  }

  _downloadFile = debounce((filename) => {
    const { datasetName, datasetVersion } = this.props;
    this.setState(
      {
        fileDownloadDisabled: true,
      },
      () => {
        axios
          .get(
            `/datasets/${datasetName}/versions/${datasetVersion}/files/${filename}/url`
          )
          .then((response) => {
            if (response.data.temporaryDownloadUrl) {
              // Use self target to stop complaints about pop-ups
              // If content-disposition headers is set to attachment
              // it opens save dialog instead of previewing/rendering it
              window.open(response.data.temporaryDownloadUrl, "_self")
            } else {
              throw new Error("Could not download file");
            }
          })
          .catch(
            // Don't swallow the error
            (error) => {
              this.setState({
                isLoaded: true,
                error,
              });
            }
          )
          .finally(
            delay(() => {
              this.setState({
                fileDownloadDisabled: false,
              });
            }, 1000)
          );
      }
    );
  }, 200);

  fileDownloadClickHandler = (event) => {
    event.preventDefault();
    this._downloadFile(event.currentTarget.dataset.filename);
  };

  render() {
    const {
      error,
      isLoaded,
      files,
      fileDownloadDisabled,
    } = this.state;
    if (error) {
      return <>Error: {error.message}</>;
    } else if (!isLoaded) {
      return <>Loading...</>;
    } else {
      return (
        <TableContainer component={Paper}>
          <Table aria-label="DatasetTable">
            <TableHead>
              <TableRow>
                <TableCell align="left" component="th" scope="col" colSpan="2">
                  Filename
                </TableCell>
                <TableCell align="left" component="th" scope="col">
                  Last Modified
                </TableCell>
                <TableCell align="left" component="th" scope="col">
                  Created <ArrowDownwardIcon fontSize="tiny"/>
                </TableCell>
                <TableCell align="left" component="th" scope="col">
                  Size
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {files
                .map((item) => (
                  <TableRow data-testid="dataset-file-row" key={item.filename}>
                    <TableCell>
                      <span>{item.filename}</span>
                    </TableCell>
                    <TableCell>
                      <Button
                        title="Download"
                        data-filename={item.filename}
                        data-browser-dataset-name={this.props.datasetName}
                        data-browser-dataset-version={this.props.datasetVersion}
                        className="custom_download_event"
                        onClick={this.fileDownloadClickHandler}
                        variant="contained"
                        color="primary"
                        disabled={fileDownloadDisabled}
                      >
                      </Button>
                    </TableCell>
                    <Tooltip title={item.lastModified}>
                      <TableCell>
                        {toRelativeTime(item.lastModified)}
                      </TableCell>
                    </Tooltip>
                    <TableCell>{item.created}</TableCell>
                    <TableCell>{bytesToSize(item.size)}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    }
  }
}

DatasetTable.propTypes = {
  datasetName: PropTypes.string.isRequired,
  datasetVersion: PropTypes.string.isRequired,
};

export default DatasetTable;
