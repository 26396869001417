import React from 'react';
import ReactDOM from 'react-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import './index.css';
import DatasetTable from './components/DatasetTable';

const paramStateMapping = {
  "dataset-name": "datasetName",
  "dataset-version": "datasetVersion"
}

function urlToStateChange(url) {
  if (!(url instanceof URL)) {
    throw Error("Not an instance of Url")
  }
  const params = url.searchParams;
  const state = {}
  for (const param of Object.keys(paramStateMapping)) {
    if (params.has(param)) {
      state[paramStateMapping[param]] = params.get(param)
    }
  }
  return state
}

const url_state = urlToStateChange(new URL(document.location))

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#01689B'
    }
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <DatasetTable datasetName={url_state.datasetName} datasetVersion={url_state.datasetVersion} />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
